<template>
  <div class="container">
    <div :class="show ? '' : 'd-none'">
      <div class="ptv bg-white" ref="documente">
        <div class="row" id="bienbantemp">
          <div class="col-12 row mb-3">
            <div class="col-4 d-flex">
              <!-- <img
                v-if="clinic && clinic.avatar"
                :src="getLogo(clinic.avatar)"
                alt=""
                width="74"
                height="74"
                class="mr-2"
              > -->
              <div class="text-uppercase">
                <p class="mb-0 fs-12">{{(organization && organization.authority_in_charge) || " " }}</p>
                <p class="mb-0 fz-11">{{(organization && organization.name) || " " }}</p>
                <p class="mb-0 fz-11">{{ (clinic && clinic.name) || "" }}</p>
                <!-- <p class="mb-0 fz-11">Địa chỉ: {{ clinic.address || '' }}</p>
                <p class="mb-0 fz-11">Số ĐT: {{ clinic.phone || '' }}</p> -->
              </div>
            </div>
            <div class="col-8 pt-3 d-flex">
              <div>
                <p class="mb-0 text-center fs-11">
                  <span class="mr-2">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</span>
                </p>
                <p class="mb-0 text-center font-weight-bold fz-11">
                  Độc lập - Tự do - Hạnh phúc
                </p>
                <p class="mb-0 text-center font-weight-bold fz-11">
                  ------------------------------------
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 temp-name">
            <p class="mb-4 text-center text-uppercase font-weight-bold fz-18">
              BIÊN BẢN HỘI CHẨN
            </p>
            <p class="mb-0 text-center fs-11 mhs">
              MHS: {{ form.profile_code || "" }}
            </p>
          </div>
          <div class="col-12 mb-2">
            <div class="d-flex">
              <div class="w-50">
                <p class="fz-12 mb-0">
                  Họ tên người bệnh:
                  <span class="fw-700 text-uppercase">{{
                    mdt_case && mdt_case.patient_name
                  }}</span>
                </p>
              </div>
              <div class="w-50 d-flex align-items-center">
                <p class="fz-12 mb-0 w-50">
                  Tuổi:
                  <span class="fw-700">{{
                    mdt_case &&
                    mdt_case.patient_birthday &&
                    mdtUtils.getNumberAges(mdt_case.patient_birthday)
                  }}</span>
                </p>
                <p class="fz-12 mb-0 w-50">
                  Nam/Nữ:
                  <span class="fw-700">{{
                    mdt_case &&
                    mdt_case.patient_gender &&
                    mdtUtils.getGender(mdt_case.patient_gender)
                  }}</span>
                </p>
              </div>
            </div>
            <div class="col-12 mb-0">
              <p class="fz-12 mb-0">Đã điều trị: {{ form.treatment_time }}</p>
            </div>
            <div class="col-12 mb-0 d-flex align-content-center">
              <div class="d-flex mb-0 w-50">
                <p class="fz-12">Chẩn đoán: {{ mdt_case && mdt_case.patient_diagnostic }}</p>
              </div>
              <p class="fz-12 mb-0 w-50">
                Hội chẩn lúc:
                {{ form && form.mdt_time && getDateTime(form.mdt_time) }}
              </p>
            </div>
            <div class="col-12 mb-0 d-flex align-content-center">
              <p class="fz-12 mb-0 w-50">
                Chủ tọa:
                {{ preside && preside.user && preside.user.name }}
              </p>
              <p class="fz-12 mb-0 w-50">
                Thư kí:
                {{
                  mdt_case && mdt_case.mdt_room_disease && mdt_case.mdt_room_disease.doctor && mdt_case.mdt_room_disease.doctor.name
                }}
              </p>
            </div>
            <div class="col-12">
              <p class="fz-12 mb-1">
                Thành viên tham gia:
                {{ form.members }}
              </p>
            </div>
            <div class="col-12 mb-1 pb-4">
              <p class="fz-12 mb-0 fw-700">
                Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc
                người bệnh:
              </p>
              <div class="content-box" v-html="form.treatment_progress"></div>
            </div>
            <div class="col-12 mb-1 pb-4">
              <p class="fz-12 mb-1 fw-700">
                Kết luận (sau khi đã khám lại và thảo luận):
              </p>
              <div class="fz-12 mb-0 pre-wrap" v-html="form.conclusion"></div>
            </div>
            <div class="col-12 mb-1 pb-4">
              <p class="fz-12 mb-1 fw-700">Hướng điều trị tiếp:</p>
              <div class="content-box" v-html="form.treatment"></div>
            </div>

            <div>
              <div class="row">
                <div class="col-4 offset-8">
                  <p class="mb-0 fz-12 text-center font-italic">
                    {{ form && form.mdt_time && getHMDMYVN(form.mdt_time) }}
                  </p>
                </div>
                <div class="col-4">
                  <p class="mb-5 fz-12 text-center text-uppercase fw-700">
                    Thư kí
                  </p>
                  <p class="mb-5 fz-12 text-center">
                    {{
                      mdt_case && mdt_case.mdt_room_disease && mdt_case.mdt_room_disease.doctor && mdt_case.mdt_room_disease.doctor.name
                    }}
                  </p>
                </div>
                <div class="col-4 offset-4">
                  <p class="mb-5 fz-12 text-center text-uppercase fw-700">
                    Chủ tọa
                  </p>
                  <p class="mb-5 fz-12 text-center">
                    {{ preside && preside.user && preside.user.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 d-flex justify-content-end" v-if="show">
      <button
        class="btn-action btn bg-pri bd-pri text-white"
        @click="exportPdf()"
      >
        In
      </button>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import mdtUtils from '../../../utils/mdtUtils'

export default {
  name: 'ConcludingTemp',
  props: ['form', 'save_pdf', 'mdt_case', 'room_info', 'show'],
  data () {
    return {
      clinic: null,
      mdtUtils,
      organization: {}
    }
  },
  mounted () {
    if (this.room_info) {
      this.organization = this.room_info.organization
    }
    if (this.mdt_case) {
      this.clinic = this.mdt_case.clinic
    }
  },
  watch: {
    save_pdf (count) {
      this.exportPdf(true)
    },
    mdt_case (data) {
      if (!data) return
      this.clinic = data?.clinic
    },
    room_info (data) {
      if (!data) return
      this.organization = data?.organization
    }
  },
  computed: {
    preside () {
      return this.room_info?.members?.find(m => m.role === 1)
    }
  },
  methods: {
    exportPdf (save = false) {
      const self = this
      const options = {
        margin: [7, 14, 7, 7],
        filename: `hoichan_${this.moment().unix()}.pdf`,
        image: { type: 'jpeg', quality: 2 },
        html2canvas: {
          dpi: 500,
          scale: 4,
          letterRendering: true,
          allowTaint: true,
          logging: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf().from(this.$refs.documente).set(options).toPdf().outputPdf()
        .then((pdf) => {
          const newpdf = btoa(pdf)
          const file = this.dataURLtoFile(
            'data:application/octet-stream;base64,' + newpdf,
            `hoichan_${self.moment().unix()}.pdf`
          )
          if (save) {
            self.$emit('onSubmiting', file)
          }
        }).catch(() => {
          self.$emit('exportError')
        })
    },
    getLogo (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + path
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    showSaveSuccess () {
      this.$toast.open({
        message: 'Lưu thành công',
        type: 'success'
      })
    },
    getDate (dateTime) {
      return this.moment(dateTime).format('DD/MM/YYYY')
    },
    getDateTime (dateTime) {
      const string = this.moment(dateTime).format('HH:mm-DD/MM/YYYY')
      return string.replace('-', ' ngày ')
    },
    getDateTime2 (dateTime) {
      return this.moment(dateTime).format('YYYY-MM-DD HH:mm')
    },
    getDateTime3 (dateTime) {
      return this.moment(dateTime).format('DD/MM/YYYY HH:mm')
    },
    getHMDMYVN (dateTime) {
      const dt = this.moment(dateTime)
      // let hm = dt.clone().format('HH:mm')
      const d = dt.clone().format('DD')
      const m = dt.clone().format('MM')
      const y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    }
  }
}
</script>
<style lang="css" scoped>
.fz-12 {
  font-size: 13px;
  color: black;
}

.fz-11 {
  font-size: 11px;
  color: black;
}

.wordbreak {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.pre-wrap {
  white-space: pre-wrap;
}
.text-red {
  color: #e70000;
}
.fz-10 {
  font-size: 10px;
  color: black;
}
.fz-16 {
  font-size: 16px;
  color: black;
}
.fz-18 {
  font-size: 18px;
  color: black;
}
.c-info {
  width: 25%;
}
.midle {
  width: 75%;
  text-align: center;
  margin-left: -12.5%;
}
.font-italic {
  font-style: italic;
}
.temp-name {
  position: relative;
}
.mhs {
  position: absolute;
  right: 16px;
  top: 0;
  font-size: 11px;
  font-style: italic;
}
</style>
