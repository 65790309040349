
<template>
  <div :class="show ? '' : 'd-none'">
    <div class="bg-white" ref="documente">
      <div class="conntainer" id="TTTQMTemp">
        <div class="bs-callout bs-callout row mb-4">
          <div class="col-6 text-uppercase">
            <p class="robo-10-700 mb-0">
              {{ (organization && organization.authority_in_charge) || " " }}
            </p>
            <p class="robo-10-400 mb-0">
              {{ organization ? organization.name : "&nbsp;" }}
            </p>
            <p class="robo-10-400 mb-0">{{ form.dept }}</p>
          </div>
          <div class="col-6 text-center">
            <p class="fs-11 mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
            <p class="fs-11 fw-700 mb-0">Độc lập - Tự do - Hạnh phúc</p>
            <p class="mb-0 text-center font-weight-bold fz-11">
              ------------------------------
            </p>
          </div>
        </div>
        <div class="bs-callout bs-callout mb-3 temp-name">
          <p class="robo-18-700 text-center font-weight-bold">THÔNG QUA MỔ</p>
          <p class="mb-0 text-center fs-11 mhs">MHS: {{ form.code || "" }}</p>
        </div>
        <div class="bs-callout bs-callout mb-3">
          <div class="row">
            <div class="col-6">
              <p class="robo-13-400 mb-1">
                Họ tên người bệnh:
                <span class="font-weight-bold text-uppercase">{{
                  form.name
                }}</span>
              </p>
              <p class="robo-13-400 mb-1">Địa chỉ: {{ form.address }}</p>
              <div class="d-flex mb-1">
                <p class="robo-13-400">
                  Chẩn đoán: {{ form && form.diagnostic }}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <p class="robo-13-400 mb-1">
                    Tuổi: <span class="font-weight-bold">{{ form.age }}</span>
                  </p>
                </div>
                <div class="col-6">
                  <p class="robo-13-400 mb-1">
                    Nam/Nữ:
                    <span class="font-weight-bold">{{
                      mdtUtils.getGender(form.sex)
                    }}</span>
                  </p>
                </div>
                <div class="col-12">
                  <p class="robo-13-400 mb-1">Khoa: {{ form.dept }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bs-callout bs-callout mb-3">
          <p class="robo-14-700 mb-2 ml-4 text-uppercase">
            I. Tóm tắt bệnh án và tình trạng bệnh nhân trước mổ
          </p>
          <div class="content-box" v-html="form.medical_history"></div>
        </div>
        <div class="bs-callout bs-callout">
          <p class="robo-14-700 mb-2 ml-4 text-uppercase">II. Xét nghiệm</p>
        </div>
        <div class="bs-callout bs-callout row mb-3">
          <div class="col-3">
            <p class="robo-13-400 mb-1">1.XN máu</p>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>HST: {{ form.test_hst }}</span> <span>g/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>HC: {{ form.test_hc }}</span> <span>T/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>HCT: {{ form.test_hct }}</span> <span>%</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>TC: {{ form.test_tc }}</span> <span>G/l</span>
            </div>
          </div>
          <div class="col-3">
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>BC: {{ form.test_bc }}</span> <span>G/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>&nbsp;</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>&nbsp;</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Nhóm Máu: {{ form.test_blood }}</span> <span></span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>PT% {{ form.test_pt }}</span> <span></span>
            </div>
          </div>
          <div class="col-3">
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Ure: {{ form.test_ure }}</span> <span>mmol/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Creatinin: {{ form.test_creatinin }}</span>
              <span>umol/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Glucose: {{ form.test_glucose }}</span> <span>mmol/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Protit: {{ form.test_protit }}</span> <span>g/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Cholesterol: {{ form.test_cholesterol }}</span>
              <span>mmol/l</span>
            </div>
          </div>
          <div class="col-3">
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Bilirubin TP: {{ form.test_bilirubin_tp }}</span>
              <span>mmol/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>Bilirubin TT: {{ form.test_bilirubin_tt }}</span>
              <span>mmol/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>GOT: {{ form.test_got }}</span> <span>U/l</span>
            </div>
            <div class="robo-13-400 mb-1 d-flex justify-content-between">
              <span>GPT: {{ form.test_gpt }}</span> <span>U/l</span>
            </div>
            <p class="robo-13-400 mb-1"></p>
            <p class="robo-13-400 mb-1"></p>
            <p class="robo-13-400 mb-1"></p>
            <p class="robo-13-400 mb-1"></p>
          </div>
          <p class="robo-13-400 mb-1">2. Nước tiểu: {{ form.urine }}</p>
          <p class="robo-13-400 mb-1">3. Xquang ngực: {{ form.xray }}</p>
          <p class="robo-13-400 mb-1">4. Điện tim: {{ form.electrocar }}</p>
          <p class="robo-13-400 mb-1">5. Siêu âm: {{ form.ultrasound }}</p>
          <p class="robo-13-400 mb-1">6. Nội soi: {{ form.endoscopy }}</p>
        </div>
        <div class="bs-callout bs-callout">
          <p class="mb-2 robo-14-700 text-center text-uppercase">
            III. Chuẩn bị bệnh nhân, kíp mổ và phương pháp mổ
          </p>
        </div>
        <div class="bs-callout bs-callout mb-3">
          <p class="robo-13-400 mb-1">
            1. Thử phản ứng thuốc:{{ form.drug_reaction }}
          </p>
          <p class="robo-13-400 mb-1">
            2. Kháng sinh trước mổ: {{ form.antibiotics }}
          </p>
          <p class="robo-13-400 mb-1">
            3. Dữ kiến phẫu thuật: {{ form.surgery_method }}
          </p>
          <p class="robo-13-400 mb-1">
            4. Phương pháp vô cảm: {{ form.anaesthetic_method }}
          </p>
          <p class="robo-13-400 mb-1">5. Kíp mổ: {{ form.surgical_team }}</p>
          <p class="robo-13-400 mb-1">
            6. Kíp gây mê: {{ form.anaesthetic_team }}
          </p>
          <p class="robo-13-400 mb-1">7. Giải phẫu bệnh: {{ form.anatomic }}</p>
          <p class="robo-13-400 mb-1">
            8. Dự trù máu: {{ form.blood_plan }}
            <span class="l-text"
              >Dự kiến ngày mổ: {{ formatDMY(form.surgery_date) }}</span
            >
          </p>
          <p class="robo-13-400 mb-1">
            9. Dự kiến khó khăn tiên lượng: {{ form.prognostic }}
          </p>
        </div>
        <div class="bs-callout bs-callout row text-center">
          <div class="col-4 text-center">
            <p class="robo-12-700 mb-5 text-uppercase">
              Trưởng khoa PT và gmhs
            </p>
            <p class="robo-13-400">{{ form.gm_sur_anes_name }}</p>
          </div>
          <div class="col-4 text-center">
            <p class="robo-12-700 mb-5 text-uppercase">Trưởng khoa ngoại</p>
            <p class="robo-13-400">{{ form.gm_surgery_name }}</p>
          </div>
          <div class="col-4 text-center">
            <p class="robo-12-700 mb-5 text-uppercase">Bác sĩ điều trị</p>
            <p class="robo-13-400">{{ form.doctor_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import mdtUtils from '../../../utils/mdtUtils'
export default {
  name: 'TQMHCHTTemplate',
  props: ['form', 'save_pdf', 'room_info', 'show'],
  data () {
    return {
      mdtUtils,
      organization: null
    }
  },
  watch: {
    save_pdf (count) {
      this.exportPdf(true)
    },
    room_info (data) {
      if (!data) return
      this.organization = data?.organization
    }
  },
  methods: {
    exportPdf (save = false) {
      const self = this
      const options = {
        margin: [7, 14, 7, 7],
        filename: `thongquamo_${this.moment().unix()}.pdf`,
        image: { type: 'jpeg', quality: 2 },
        html2canvas: {
          dpi: 500,
          scale: 4,
          letterRendering: true,
          allowTaint: true,
          logging: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf().from(this.$refs.documente).set(options).toPdf().outputPdf()
        .then((pdf) => {
          const newpdf = btoa(pdf)
          const file = this.dataURLtoFile(
            'data:application/octet-stream;base64,' + newpdf,
            `thongquamo_${this.moment().unix()}.pdf`
          )
          if (save) {
            self.$emit('onSubmiting', file)
          }
        }).catch(() => {
          self.$emit('exportError')
        })
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    getDateTime (dateTime) {
      const string = this.moment(dateTime).format('HH:mm-DD/MM/YYYY')
      return string.replace('-', ' ngày ')
    },
    formatDMY (d) {
      return this.moment(d).format('DD/MM/YYYY')
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    }
  }
}
</script>
<style scoped>
.conntainer {
  width: 642.52px;
  margin: 0;
  padding: 0;
}
.l-text {
  margin-left: 230px;
}
.robo-10-700 {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-11-700 {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-13-700 {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-18-700 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-16-700 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-13-400 {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}
.robo-10-400 {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}
.robo-10-400 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}
.textarea {
  white-space: pre-line;
}
.fs-11 {
  font-size: 11px;
}
.temp-name {
  position: relative;
}
.mhs {
  position: absolute;
  right: 16px;
  top: 0;
  font-size: 11px;
  font-style: italic;
}
</style>
