<template>
  <div class="p-3 txt-grey-900">
    <div class="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
      <h5>Thông qua mổ</h5>
      <span class="fs-30 cursor-pointer" @click="backToSlide">&times;</span>
    </div>
    <div class="m-0 mb-4 row">
      <div class="col-6">
        <p class="fs-14">Họ tên người bệnh: {{ form.name }}</p>
        <p class="fs-14">Địa chỉ: {{ form.address }}</p>
        <div class="d-flex mb-2">
          <p class="fs-14">Chẩn đoán: {{ form && form.diagnostic }}</p>
        </div>
      </div>
      <div class="col-4" id="khoa">
        <p class="fs-14">Tuổi: {{ form.age }}</p>
        <div class="d-flex align-items-center">
          <p class="fs-14 mb-0 mr-2">Khoa:</p>
          <input
            type="text"
            class="border w-100 fs-20"
            v-model="form.dept"
            :disabled="!isMaster"
            @focus="focusInput('khoa', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-2">
        <p class="fs-14">Nam/Nữ: {{ getGender(form.sex) }}</p>
      </div>
    </div>
    <!-- <div class="d-flex align-items-center mb-2">
      <p class="fs-14 mb-0 mr-2">Hội chẩn lúc:</p>
      <input type="datetime-local" class="border" v-model="form.mdt_time" />
    </div>-->
    <div class="m-0 mb-4" id="tt">
      <div>
        <label class="fs-16 fw-500 fw-bold">I. Tóm tắt bệnh án và tình trạng bệnh nhân trước mổ</label>
        <vue-editor
          v-model="form.medical_history"
          :editorToolbar="customToolbar"
          :disabled="!isMaster"
          @focus="focusInput('tt', $event)"
          @blur="focusInput('', $event)"
        ></vue-editor>
      </div>
    </div>
    <div class="fs-16 fw-500">
      <p>II. Xét nghiệm</p>
    </div>
    <div class="fs-16 fw-500">
      <p>1. XN máu</p>
    </div>
    <div class="m-0 mb-3 row">
      <div class="col-6" id="bc">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">BC</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_bc"
            :readonly="!isMaster"
            @focus="focusInput('bc', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="ure">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Ure</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_ure"
            :readonly="!isMaster"
            @focus="focusInput('ure', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="bilirubintp">
        <div class="form-group">
          <label for="bilirubintp" class="fs-14 fw-500 fw-bold">Bilirubin TP</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_bilirubin_tp"
            :readonly="!isMaster"
            @focus="focusInput('bilirubintp', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3 row">
      <div class="col-6" id="hst">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">HST</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_hst"
            :readonly="!isMaster"
            @focus="focusInput('hst', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="creatinin">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Creatinin</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_creatinin"
            :readonly="!isMaster"
            @focus="focusInput('creatinin', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="bilirubintt">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Bilirubin TT</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_bilirubin_tt"
            :readonly="!isMaster"
            @focus="focusInput('bilirubintt', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3 row">
      <div class="col-6" id="hc">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">HC</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_hc"
            :readonly="!isMaster"
            @focus="focusInput('hc', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="glucose">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Glucose</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_glucose"
            :readonly="!isMaster"
            @focus="focusInput('glucose', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="got">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">GOT</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_got"
            :readonly="!isMaster"
            @focus="focusInput('got', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3 row">
      <div class="col-3" id="hct">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">HCT</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_hct"
            :readonly="!isMaster"
            @focus="focusInput('hct', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="bloodg">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Nhóm Máu:</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_blood"
            :readonly="!isMaster"
            @focus="focusInput('bloodg', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="protit">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Protit</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_protit"
            :readonly="!isMaster"
            @focus="focusInput('protit', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="gpt">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">GPT</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_gpt"
            :readonly="!isMaster"
            @focus="focusInput('gpt', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3 row">
      <div class="col-3" id="tc">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">TC</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_tc"
            :readonly="!isMaster"
            @focus="focusInput('tc', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-3" id="pt">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">PT%</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_pt"
            :readonly="!isMaster"
            @focus="focusInput('pt', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
      <div class="col-6" id="Cholesterol">
        <div class="form-group">
          <label class="fs-14 fw-500 fw-bold">Cholesterol</label>
          <input
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_cholesterol"
            :readonly="!isMaster"
            @focus="focusInput('Cholesterol', $event)"
            @blur="focusInput('', $event)"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3" id="piss">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">2. Nước tiểu</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.urine"
          :readonly="!isMaster"
          @focus="focusInput('piss', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="xray">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">3. Xquang Ngực</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.xray"
          :readonly="!isMaster"
          @focus="focusInput('xray', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="electrocar">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">4. Điện tim</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.electrocar"
          :readonly="!isMaster"
          @focus="focusInput('electrocar', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="ultrasound">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">5. Siêu âm</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.ultrasound"
          :readonly="!isMaster"
          @focus="focusInput('ultrasound', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-4" id="endoscopy">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">6. Nội soi</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.endoscopy"
          :readonly="!isMaster"
          @focus="focusInput('endoscopy', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="fs-16 fw-500">
      <p>III. Chuẩn bị bệnh nhân, kíp mổ và phương pháp mổ</p>
    </div>
    <div class="m-0 mb-3" id="drug_reaction">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">1. Thử phản ứng thuốc</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.drug_reaction"
          :readonly="!isMaster"
          @focus="focusInput('drug_reaction', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="antibiotics">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">2. Kháng sinh trước mổ</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.antibiotics"
          :readonly="!isMaster"
          @focus="focusInput('antibiotics', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="surgery_method">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">3. Phương pháp phẫu thuật</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.surgery_method"
          :readonly="!isMaster"
          @focus="focusInput('surgery_method', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="anaesthetic_method">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">4. Phương pháp Vô cảm</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.anaesthetic_method"
          :readonly="!isMaster"
          @focus="focusInput('anaesthetic_method', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="surgical_team">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">5. Kíp mổ</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.surgical_team"
          :readonly="!isMaster"
          @focus="focusInput('surgical_team', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="anaesthetic_team">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">6. Kíp gây mê</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.anaesthetic_team"
          :readonly="!isMaster"
          @focus="focusInput('anaesthetic_team', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="anatomic">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">7. Giải phẫu bệnh</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.anatomic"
          :readonly="!isMaster"
          @focus="focusInput('anatomic', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3 row">
      <div class="form-group col-6" id="blood_plan">
        <label class="fs-14 fw-500 fw-bold">8. Dự trù máu</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.blood_plan"
          :readonly="!isMaster"
          @focus="focusInput('blood_plan', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
      <div class="form-group col-6" id="surgery_date">
        <label class="fs-14 fw-500 fw-bold">Dự kiến ngày mổ</label>
        <input
          type="date"
          class="form-control mt-1"
          placeholder
          v-model="form.surgery_date"
          :readonly="!isMaster"
          @focus="focusInput('surgery_date', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-4" id="prognostic">
      <div class="form-group">
        <label class="fs-14 fw-500 fw-bold">9. Dự kiến khó khăn tiên lượng</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.prognostic"
          :readonly="!isMaster"
          @focus="focusInput('prognostic', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="fs-16 fw-500">
      <p>Người kí biên bản</p>
    </div>
    <div class="m-0 mb-3" id="gm_sur_anes_name">
      <div class="form-group">
        <label class="fs-16 fw-500 fw-bold">Trưởng khoa PT và GMHS</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.gm_sur_anes_name"
          :readonly="!isMaster"
          @focus="focusInput('gm_sur_anes_name', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="gm_surgery_name">
      <div class="form-group">
        <label class="fs-16 fw-500 fw-bold">Trưởng Khoa Ngoại</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.gm_surgery_name"
          :readonly="!isMaster"
          @focus="focusInput('gm_surgery_name', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="m-0 mb-3" id="doctor_name">
      <div class="form-group">
        <label class="fs-16 fw-500 fw-bold">Bác sĩ điều trị</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.doctor_name"
          :readonly="!isMaster"
          @focus="focusInput('doctor_name', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
    </div>
    <div class="my-3 text-end">
      <!-- <button
        :disabled="!isMaster || loading"
        class="btn bg-pri bd-pri text-white px-3 me-2"
        @click="backToSlide"
      >Quay lại trình bày</button>-->
      <button
        class="btn bg-pri bd-pri text-white px-5"
        @click="submit()"
        :disabled="!isMaster || loading"
      >Lưu</button>
    </div>
    <TTTQMTemplate
      :form="form"
      :room_info="room_info"
      :save_pdf="save_pdf"
      @onSubmiting="onSubmiting"
    />
  </div>
</template>

<script>
import TTTQMTemplate from './TQMHCHTTemplate.vue'
import mdtUtils from '../../../utils/mdtUtils'
import moment from 'moment'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'TQMForm',
  components: { TTTQMTemplate, VueEditor },
  props: ['currCase', 'room_info', 'is_player', 'prop_form', 'prop_el_id'],
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block', 'link'],
        [
          {
            align: ''
          },
          {
            align: 'center'
          },
          {
            align: 'right'
          },
          {
            align: 'justify'
          }
        ]
      ],
      form: {
        name: '',
        age: '',
        sex: '',
        address: '',
        code: '',
        dept: '',
        diagnostic: '',
        mdt_time: '',
        treatment_progress: '',
        treatment_time: '',
        medical_history: '',
        test_bc: '',
        test_ure: '',
        test_bilirubin_tp: '',
        test_bilirubin_tt: '',
        test_hst: '',
        test_creatinin: '',
        test_hc: '',
        test_glucose: '',
        test_got: '',
        test_hct: '',
        test_blood: '',
        test_protit: '',
        test_gpt: '',
        test_tc: '',
        test_pt: '',
        test_cholesterol: '',
        urine: '',
        xray: '',
        electrocar: '',
        ultrasound: '',
        endoscopy: '',
        drug_reaction: '',
        antibiotics: '',
        surgery_method: '',
        surgical_team: '',
        blood_plan: '',
        surgery_date: moment().format('YYYY-MM-DD'),
        prognostic: '',
        gm_sur_anes_name: '',
        gm_surgery_name: '',
        doctor_name: '',
        anaesthetic_method: '',
        anatomic: '',
        anaesthetic_team: ''
      },
      save_pdf: 0,
      doctor_id: null,
      loading: false
    }
  },
  mounted () {
    this.doctor_id = this.$user?.doctor?.id
    this.processDisease(this.currCase)
    if (this.prop_el_id) {
      document.getElementById(this.prop_el_id).scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    }
  },
  computed: {
    isMaster () {
      if (!this.usr_member) return false
      // return this.usr_member?.role === 4 || this.usr_member?.role === 1
      return true
    },
    usr_member () {
      return this.room_info?.members?.find(m => m.user_id === this.$user?.id)
    }
  },
  watch: {
    currCase (d) {
      this.processDisease(d)
      if (d.clinic && !this.form.dept) {
        this.form.dept = d.clinic.name
      }
    },
    prop_form (f) {
      if (f) {
        this.form = f
      }
    },
    prop_el_id (id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    }
  },
  methods: {
    backToSlide () {
      this.$emit('changeShowType', 'slide')
    },
    processDisease (d) {
      if (d) {
        if (d.operation) {
          this.form = {
            name: d.operation.name,
            age: d.operation.age,
            sex: d.operation.sex,
            address: d.operation.address,
            code: d.operation.code,
            dept: d.operation.dept,
            diagnostic: d.operation.diagnostic,
            treatment_progress: d.operation.treatment_progress,
            treatment_time: d.operation.treatment_time,
            // mdt_time: d.operation.mdt_time ? moment(d.operation.mdt_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm') : moment(this.room_info.start_time, 'HH:mm:ss').format('YYYY-MM-DDTHH:mm'),
            medical_history: d.operation.medical_history || '',
            test_bc: d.operation.test_bc,
            test_ure: d.operation.test_ure,
            test_bilirubin_tp: d.operation.test_bilirubin_tp,
            test_bilirubin_tt: d.operation.test_bilirubin_tt,
            test_hst: d.operation.test_hst,
            test_creatinin: d.operation.test_creatinin,
            test_hc: d.operation.test_hc,
            test_glucose: d.operation.test_glucose,
            test_got: d.operation.test_got,
            test_hct: d.operation.test_hct,
            test_blood: d.operation.test_blood,
            test_protit: d.operation.test_protit,
            test_gpt: d.operation.test_gpt,
            test_tc: d.operation.test_tc,
            test_pt: d.operation.test_pt,
            test_cholesterol: d.operation.test_cholesterol,
            urine: d.operation.urine,
            xray: d.operation.xray,
            electrocar: d.operation.electrocar,
            ultrasound: d.operation.ultrasound,
            endoscopy: d.operation.endoscopy,
            drug_reaction: d.operation.drug_reaction,
            antibiotics: d.operation.antibiotics,
            surgery_method: d.operation.surgery_method,
            surgical_team: d.operation.surgical_team,
            blood_plan: d.operation.blood_plan,
            surgery_date:
              d.operation.surgery_date || moment().format('YYYY-MM-DD'),
            prognostic: d.operation.prognostic,
            gm_sur_anes_name: d.operation.gm_sur_anes_name,
            gm_surgery_name: d.operation.gm_surgery_name,
            doctor_name: d.operation.doctor_name,
            anaesthetic_method: d.operation.anaesthetic_method,
            anaesthetic_team: d.operation.anaesthetic_team,
            anatomic: d.operation.anatomic
          }
        } else {
          if (d.mdt_room_disease?.operation) {
            const mdtRDOperation = d.mdt_room_disease?.operation
            this.form = {
              name: mdtRDOperation.name,
              age: mdtRDOperation.age,
              sex: mdtRDOperation.sex,
              address: mdtRDOperation.address,
              code: mdtRDOperation.code,
              dept: mdtRDOperation.dept,
              diagnostic: mdtRDOperation.diagnostic,
              treatment_progress: mdtRDOperation.treatment_progress,
              treatment_time: mdtRDOperation.treatment_time,
              // mdt_time: mdtRDOperation.mdt_time ? moment(mdtRDOperation.mdt_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm') : moment(this.room_info.start_time, 'HH:mm:ss').format('YYYY-MM-DDTHH:mm'),
              medical_history: mdtRDOperation.medical_history || '',
              test_bc: mdtRDOperation.test_bc,
              test_ure: mdtRDOperation.test_ure,
              test_bilirubin_tp: mdtRDOperation.test_bilirubin_tp,
              test_bilirubin_tt: mdtRDOperation.test_bilirubin_tt,
              test_hst: mdtRDOperation.test_hst,
              test_creatinin: mdtRDOperation.test_creatinin,
              test_hc: mdtRDOperation.test_hc,
              test_glucose: mdtRDOperation.test_glucose,
              test_got: mdtRDOperation.test_got,
              test_hct: mdtRDOperation.test_hct,
              test_blood: mdtRDOperation.test_blood,
              test_protit: mdtRDOperation.test_protit,
              test_gpt: mdtRDOperation.test_gpt,
              test_tc: mdtRDOperation.test_tc,
              test_pt: mdtRDOperation.test_pt,
              test_cholesterol: mdtRDOperation.test_cholesterol,
              urine: mdtRDOperation.urine,
              xray: mdtRDOperation.xray,
              electrocar: mdtRDOperation.electrocar,
              ultrasound: mdtRDOperation.ultrasound,
              endoscopy: mdtRDOperation.endoscopy,
              drug_reaction: mdtRDOperation.drug_reaction,
              antibiotics: mdtRDOperation.antibiotics,
              surgery_method: mdtRDOperation.surgery_method,
              surgical_team: mdtRDOperation.surgical_team,
              blood_plan: mdtRDOperation.blood_plan,
              surgery_date:
                mdtRDOperation.surgery_date || moment().format('YYYY-MM-DD'),
              prognostic: mdtRDOperation.prognostic,
              gm_sur_anes_name: mdtRDOperation.gm_sur_anes_name,
              gm_surgery_name: mdtRDOperation.gm_surgery_name,
              doctor_name: mdtRDOperation.doctor_name,
              anaesthetic_method: mdtRDOperation.anaesthetic_method,
              anaesthetic_team: mdtRDOperation.anaesthetic_team,
              anatomic: mdtRDOperation.anatomic
            }
          } else {
            this.form = {
              name: d.patient_name,
              age: this.getAges(d.patient_birthday),
              sex: d.patient_gender,
              address: d.patient_address,
              code: d.code,
              diagnostic: d.patient_diagnostic,
              medical_history: d.patient_diseases_current_cure,
              // mdt_time: d.start_time ? moment(d.start_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm'),
              dept: this.form.dept || '',
              treatment_progress: '',
              treatment_time: '',
              test_bc: '',
              test_ure: '',
              test_bilirubin_tp: '',
              test_bilirubin_tt: '',
              test_hst: '',
              test_creatinin: '',
              test_hc: '',
              test_glucose: '',
              test_got: '',
              test_hct: '',
              test_blood: '',
              test_protit: '',
              test_gpt: '',
              test_tc: '',
              test_pt: '',
              test_cholesterol: '',
              urine: '',
              xray: '',
              electrocar: '',
              ultrasound: '',
              endoscopy: '',
              drug_reaction: '',
              antibiotics: '',
              surgery_method: d.surgery_method,
              surgical_team: '',
              blood_plan: '',
              surgery_date: moment().format('YYYY-MM-DD'),
              prognostic: '',
              gm_sur_anes_name: '',
              gm_surgery_name: '',
              doctor_name: d.doctor?.name,
              anaesthetic_method: '',
              anaesthetic_team: '',
              anatomic: ''
            }
          }
        }
      }
    },
    submit () {
      if (!this.isMaster) return
      this.loading = true
      this.save_pdf++
    },
    async onSubmiting (file) {
      if (!this.currCase) return
      if (this.currCase.operation) {
        await this.updateMdtDiseaseOperation(file)
      } else {
        await this.createMdtDiseaseOperation(file)
      }
    },
    async createMdtDiseaseOperation (file = null) {
      const self = this
      // if (self.doctor_id !== self.currCase.doctor_id) return
      const params = { ...this.form }
      params.public_mdt_case_id = self.currCase.id
      // params.mdt_time = moment(params.mdt_time).format('HH:mm DD-MM-YYYY')
      params.surgery_date = moment(params.surgery_date).format('DD-MM-YYYY')
      if (file) params.file = file
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .createPublicMdtCaseOperation(formData)
        .then(r => {
          self.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
          self.$emit('refresh')
          this.backToSlide()
        })
        .finally(() => {
          self.loading = false
        })
    },
    async updateMdtDiseaseOperation (file = null) {
      const self = this
      // if (self.doctor_id !== self.currCase.doctor_id || !self.currCase.operation) return
      const params = { ...this.form }
      params.public_mdt_case_id = self.currCase.id
      // params.mdt_time = moment(params.mdt_time).format('HH:mm DD-MM-YYYY')
      params.surgery_date = moment(params.surgery_date).format('DD-MM-YYYY')
      if (file) params.file = file
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .updatePublicMdtCaseOperation(self.currCase.operation.id, formData)
        .then(() => {
          self.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
          self.$emit('refresh')
          this.backToSlide()
        })
        .finally(() => {
          self.loading = false
        })
    },
    getAges (date) {
      const bd = moment(date, 'YYYY-MM-DD')
      const age = moment().diff(bd, 'years')
      return age
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    focusInput (id, e) {
      if (!this.isMaster) return
      this.$emit('changeOperationForm', this.form, id || null)
    }
  }
}
</script>

<style scoped>
</style>
