<template>
  <div style="mdt-info">
    <div class="mb-4 border-bottom d-flex justify-content-between header-info">
      <p class="mb-2 fs-16 fw-500">Danh sách các ca bệnh</p>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideDisease"
        class="c-pointer"
      >
        <path
          d="M6 17.9998L17.9998 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 17.9998L6 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="input-group border radius-10 search-disease me-2">
        <div class="input-group-text border-0 bg-white radius-10">
          <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.1006 21.5492C23.396 21.8448 23.396 22.3241 23.1006 22.6198C22.8051 22.9154 22.326 22.9154 22.0306 22.6198L18.3989 18.986C18.1034 18.6904 18.1034 18.2111 18.3989 17.9154C18.6943 17.6198 19.1734 17.6198 19.4689 17.9154L23.1006 21.5492ZM11.6704 19.2078C7.24108 19.2078 3.65039 15.615 3.65039 11.1832C3.65039 6.7514 7.24108 3.15869 11.6704 3.15869C16.0998 3.15869 19.6905 6.7514 19.6905 11.1832C19.6905 15.615 16.0998 19.2078 11.6704 19.2078ZM11.6704 17.6937C15.264 17.6937 18.1772 14.7789 18.1772 11.1832C18.1772 7.58759 15.264 4.67275 11.6704 4.67275C8.07681 4.67275 5.1636 7.58759 5.1636 11.1832C5.1636 14.7789 8.07681 17.6937 11.6704 17.6937ZM7.93783 10.5776C7.93783 10.8563 7.712 11.0823 7.43343 11.0823C7.15485 11.0823 6.92902 10.8563 6.92902 10.5776C6.92902 8.292 8.78082 6.43916 11.0651 6.43916C11.3437 6.43916 11.5695 6.66512 11.5695 6.94385C11.5695 7.22258 11.3437 7.44853 11.0651 7.44853C9.33797 7.44853 7.93783 8.84946 7.93783 10.5776Z"
              fill="#ABABAC"
            />
          </svg>
        </div>
        <input
          class="radius-10 form-control txt-black bg-white border-0"
          v-model="search"
          placeholder="Nhập tên bác sĩ, bệnh nhân"
        />
      </div>
    </div>
    <div :set="(cases = filteredAndSorted)">
      <div
        class="mb-3 border radius-10 p-3 case-item"
        v-for="disease in cases"
        :key="disease.id"
        @click="
          showPublicMdtCaseDetail(
            disease.id
          )
        "
        :class="showing_id === disease.id ? 'bg-active-disease' : 'bg-white'"
      >
        <div class="d-flex justify-content-between">
          <p
            class="fs-16 fw-600 mb-2 max-line-1 txt-grey-900"
            v-if="disease"
          >{{ disease.patient_diagnostic }}</p>
          <p
            class="mb-3 fs-16 fw-500"
            :class="disease.status === 3 ? 'txt-err-700' : 'txt-pri'"
            v-if="disease.type"
          >
            {{disease.status === 2
            ? "Thông qua"
            : disease.status === 3
            ? "Không thông qua"
            : ""
            }}
          </p>
          <p
            class="mb-3 fs-16 fw-500"
            :class="disease.status !== 2 ? 'txt-err-700' : 'txt-pri'"
            v-else
          >
            {{disease.status === 2
            ? "Đã Duyệt"
            : "Chưa Duyệt"
            }}
          </p>
        </div>
        <div class="mb-2 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
              >
                <path
                  d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM7.07 18.78C7.5 17.88 10.12 17 12 17C13.88 17 16.51 17.88 16.93 18.78C15.57 19.86 13.86 20.5 12 20.5C10.14 20.5 8.43 19.86 7.07 18.78ZM18.36 17.33C16.93 15.59 13.46 15 12 15C10.54 15 7.07 15.59 5.64 17.33C4.62 15.99 4 14.32 4 12.5C4 8.09 7.59 4.5 12 4.5C16.41 4.5 20 8.09 20 12.5C20 14.32 19.38 15.99 18.36 17.33ZM12 6.5C10.06 6.5 8.5 8.06 8.5 10C8.5 11.94 10.06 13.5 12 13.5C13.94 13.5 15.5 11.94 15.5 10C15.5 8.06 13.94 6.5 12 6.5ZM12 11.5C11.17 11.5 10.5 10.83 10.5 10C10.5 9.17 11.17 8.5 12 8.5C12.83 8.5 13.5 9.17 13.5 10C13.5 10.83 12.83 11.5 12 11.5Z"
                  fill="#475467"
                />
              </svg>

              <p
                class="fs-14 mb-2 max-line-2 txt-black"
                v-if="disease"
              >{{ mdtUtils.getMdtPersonInfoString(disease) }}</p>
            </div>
            <div class="d-flex">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
              >
                <path
                  d="M7.7162 15.0014C7.71327 15.0014 7.70975 15.0014 7.70682 15.0014C7.50643 14.9982 7.32069 14.8951 7.2117 14.7263L5.36366 11.8585C5.18436 11.5802 5.26463 11.2087 5.54295 11.0291C5.82186 10.8501 6.19276 10.9298 6.37264 11.2087L7.73319 13.3201L9.18397 11.2166C9.37264 10.9442 9.74588 10.8747 10.0183 11.0634C10.2914 11.2515 10.3599 11.6253 10.1719 11.8981L8.21014 14.7422C8.09823 14.9048 7.91307 15.0014 7.7162 15.0014Z"
                  fill="#475467"
                  stroke="#475467"
                  stroke-width="0.75"
                />
                <path
                  d="M7.8 12.6015C5.15332 12.6015 3 10.4482 3 7.80153V4.20156C3 3.87021 3.26836 3.60156 3.6 3.60156H4.8C5.13164 3.60156 5.4 3.87021 5.4 4.20156C5.4 4.5329 5.13164 4.80155 4.8 4.80155H4.2V7.80153C4.2 9.78667 5.81485 11.4015 7.8 11.4015C9.78516 11.4015 11.4 9.78667 11.4 7.80153V4.80155H10.8C10.4684 4.80155 10.2 4.5329 10.2 4.20156C10.2 3.87021 10.4684 3.60156 10.8 3.60156H12C12.3316 3.60156 12.6 3.87021 12.6 4.20156V7.80153C12.6 10.4482 10.4467 12.6015 7.8 12.6015Z"
                  fill="#475467"
                  stroke="#475467"
                  stroke-width="0.75"
                />
                <path
                  d="M4.8002 5.39998C4.46855 5.39998 4.2002 5.13133 4.2002 4.79999V3.6C4.2002 3.26865 4.46855 3 4.8002 3C5.13184 3 5.4002 3.26865 5.4002 3.6V4.79999C5.4002 5.13133 5.13184 5.39998 4.8002 5.39998Z"
                  fill="#475467"
                  stroke="#475467"
                  stroke-width="0.75"
                />
                <path
                  d="M10.8002 5.39998C10.4686 5.39998 10.2002 5.13133 10.2002 4.79999V3.6C10.2002 3.26865 10.4686 3 10.8002 3C11.1318 3 11.4002 3.26865 11.4002 3.6V4.79999C11.4002 5.13133 11.1318 5.39998 10.8002 5.39998Z"
                  fill="#475467"
                  stroke="#475467"
                  stroke-width="0.75"
                />
                <path
                  d="M12.9002 20.9984C9.75723 20.9984 7.2002 18.4413 7.2002 15.2984V14.3984C7.2002 14.0671 7.46855 13.7984 7.8002 13.7984C8.13184 13.7984 8.4002 14.0671 8.4002 14.3984V15.2984C8.4002 17.7798 10.4188 19.7984 12.9002 19.7984C15.3816 19.7984 17.4002 17.7798 17.4002 15.2984V11.9984C17.4002 11.6671 17.6686 11.3984 18.0002 11.3984C18.3318 11.3984 18.6002 11.6671 18.6002 11.9984V15.2984C18.6002 18.4413 16.0432 20.9984 12.9002 20.9984Z"
                  fill="#475467"
                  stroke="#475467"
                  stroke-width="0.75"
                />
                <path
                  d="M18 6.60156C16.3459 6.60156 15 7.94745 15 9.60154C15 11.2556 16.3459 12.6015 18 12.6015C19.6541 12.6015 21 11.2556 21 9.60154C21 7.94745 19.6541 6.60156 18 6.60156Z"
                  fill="#475467"
                  stroke="#475467"
                  stroke-width="0.75"
                />
              </svg>

              <p
                class="fs-14 mb-0 max-line-1 txt-blur-gray"
                v-if="
                  disease.mdt_room_disease && disease.mdt_room_disease.doctor
                "
              >{{ disease.mdt_room_disease.doctor.name }}</p>
            </div>
          </div>
          <div>
            <div
              class="txt-pri c-pointer text-decoration-underline fs-12 fw-500"
              v-if="
                disease &&
                disease.concluding &&
                disease.concluding.pdf
              "
              @click="showBB(disease.concluding.pdf, $event)"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_18231_33533)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2999 0H28.3479L41.949 14.1769V41.7512C41.949 45.2054 39.1545 48 35.7121 48H12.2999C8.84562 48 6.05103 45.2054 6.05103 41.7512V6.24885C6.05096 2.79459 8.84556 0 12.2999 0V0Z"
                    fill="#E5252A"
                  />
                  <path
                    opacity="0.302"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.3359 0V14.069H41.9492L28.3359 0Z"
                    fill="white"
                  />
                  <path
                    d="M12.9956 35.8145V27.0469H16.7258C17.6493 27.0469 18.381 27.2987 18.9327 27.8145C19.4844 28.3182 19.7603 29.0019 19.7603 29.8535C19.7603 30.705 19.4844 31.3887 18.9327 31.8924C18.381 32.4082 17.6493 32.6601 16.7258 32.6601H15.2385V35.8145H12.9956ZM15.2385 30.7531H16.4739C16.8097 30.7531 17.0736 30.6811 17.2535 30.5132C17.4334 30.3573 17.5294 30.1414 17.5294 29.8535C17.5294 29.5657 17.4335 29.3498 17.2535 29.1939C17.0736 29.0259 16.8098 28.954 16.4739 28.954H15.2385V30.7531ZM20.6838 35.8145V27.0469H23.7902C24.4019 27.0469 24.9776 27.1308 25.5173 27.3108C26.057 27.4906 26.5488 27.7426 26.9806 28.0904C27.4124 28.4262 27.7602 28.8819 28.0121 29.4577C28.252 30.0334 28.3839 30.6931 28.3839 31.4367C28.3839 32.1683 28.252 32.828 28.0121 33.4037C27.7602 33.9794 27.4124 34.4351 26.9806 34.771C26.5488 35.1188 26.057 35.3706 25.5173 35.5506C24.9776 35.7305 24.4019 35.8145 23.7902 35.8145H20.6838ZM22.8787 33.9075H23.5263C23.8741 33.9075 24.198 33.8715 24.4979 33.7875C24.7857 33.7036 25.0616 33.5716 25.3255 33.3917C25.5773 33.2118 25.7812 32.9599 25.9251 32.6241C26.0691 32.2883 26.141 31.8924 26.141 31.4367C26.141 30.9689 26.0691 30.5731 25.9251 30.2373C25.7812 29.9015 25.5773 29.6496 25.3255 29.4697C25.0616 29.2898 24.7857 29.1578 24.4979 29.0739C24.198 28.99 23.8741 28.9539 23.5263 28.9539H22.8787V33.9075ZM29.5114 35.8145V27.0469H35.7483V28.9539H31.7543V30.3572H34.9446V32.2523H31.7543V35.8145H29.5114Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_18231_33533">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Xem BBHC
            </div>
            <div
              class="txt-pri c-pointer text-decoration-underline fs-12 fw-500"
              v-if="disease.operation && disease.operation.pdf && disease.type"
              @click="showTQM(disease.operation.pdf, $event)"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_18231_33533)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2999 0H28.3479L41.949 14.1769V41.7512C41.949 45.2054 39.1545 48 35.7121 48H12.2999C8.84562 48 6.05103 45.2054 6.05103 41.7512V6.24885C6.05096 2.79459 8.84556 0 12.2999 0V0Z"
                    fill="#E5252A"
                  />
                  <path
                    opacity="0.302"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.3359 0V14.069H41.9492L28.3359 0Z"
                    fill="white"
                  />
                  <path
                    d="M12.9956 35.8145V27.0469H16.7258C17.6493 27.0469 18.381 27.2987 18.9327 27.8145C19.4844 28.3182 19.7603 29.0019 19.7603 29.8535C19.7603 30.705 19.4844 31.3887 18.9327 31.8924C18.381 32.4082 17.6493 32.6601 16.7258 32.6601H15.2385V35.8145H12.9956ZM15.2385 30.7531H16.4739C16.8097 30.7531 17.0736 30.6811 17.2535 30.5132C17.4334 30.3573 17.5294 30.1414 17.5294 29.8535C17.5294 29.5657 17.4335 29.3498 17.2535 29.1939C17.0736 29.0259 16.8098 28.954 16.4739 28.954H15.2385V30.7531ZM20.6838 35.8145V27.0469H23.7902C24.4019 27.0469 24.9776 27.1308 25.5173 27.3108C26.057 27.4906 26.5488 27.7426 26.9806 28.0904C27.4124 28.4262 27.7602 28.8819 28.0121 29.4577C28.252 30.0334 28.3839 30.6931 28.3839 31.4367C28.3839 32.1683 28.252 32.828 28.0121 33.4037C27.7602 33.9794 27.4124 34.4351 26.9806 34.771C26.5488 35.1188 26.057 35.3706 25.5173 35.5506C24.9776 35.7305 24.4019 35.8145 23.7902 35.8145H20.6838ZM22.8787 33.9075H23.5263C23.8741 33.9075 24.198 33.8715 24.4979 33.7875C24.7857 33.7036 25.0616 33.5716 25.3255 33.3917C25.5773 33.2118 25.7812 32.9599 25.9251 32.6241C26.0691 32.2883 26.141 31.8924 26.141 31.4367C26.141 30.9689 26.0691 30.5731 25.9251 30.2373C25.7812 29.9015 25.5773 29.6496 25.3255 29.4697C25.0616 29.2898 24.7857 29.1578 24.4979 29.0739C24.198 28.99 23.8741 28.9539 23.5263 28.9539H22.8787V33.9075ZM29.5114 35.8145V27.0469H35.7483V28.9539H31.7543V30.3572H34.9446V32.2523H31.7543V35.8145H29.5114Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_18231_33533">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Xem TQM
            </div>
          </div>
        </div>
      </div>
      <div v-if="!cases || !cases.length" class="text-center">
        <img src="../../../public/assets/images/mdt/empty.svg" alt class="my-4" width="270" />
        <p class="fs-18">{{ search ? "Không tìm thấy kết quả nào" : "Chưa có ca bệnh nào" }}</p>
        <!-- <button class="btn btn-primary bg-pri bd-pri w-50" @click="showAddDisease(true)">Thêm</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
export default {
  name: 'MdtDisease',
  props: ['mdt_cases', 'showing_id'],
  components: {},
  data () {
    return {
      mdtUtils,
      appUtils,
      search: '',
      doctor_id: null,
      status: 0,
      page: 0,
      loading: false
    }
  },
  computed: {
    filteredAndSorted () {
      const searchString = appUtils.removeVietnameseTones(
        this.search.toLowerCase()
      )
      if (this.mdt_cases && this.mdt_cases.total) {
        const result = this.mdt_cases?.data.filter(d => {
          return (
            appUtils
              .removeVietnameseTones(d.patient_name.toLowerCase())
              .includes(searchString) ||
            (d?.mdt_room_disease?.doctor &&
              appUtils
                .removeVietnameseTones(
                  d.mdt_room_disease.doctor.name.toLowerCase()
                )
                .includes(searchString))
          )
        })
        return result
      } else {
        return []
      }
    }
  },
  mounted () {
    this.doctor_id = this.$user?.doctor?.id
  },
  methods: {
    showAddDisease (show) {
      this.$emit('showAddDisease', show)
    },
    refreshDisease () {
      this.$emit('refreshDisease')
    },
    hideDisease () {
      this.$emit('hideDisease')
    },
    showPublicMdtCaseDetail (id) {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = `https://dev.hodo.app/#/organization/mdt-case/${id}?token=${appUtils.getLocalToken()}`
      } else {
        dm = `${user?.domain ||
          'https://hodo.app'}/#/organization/mdt-case/${id}?token=${appUtils.getLocalToken()}`
      }
      window.open(dm, '_blank')
    },
    showTrichBienBan (src, e) {
      e.stopPropagation()
      window.open(src)
    },
    showBB (src, e) {
      e.stopPropagation()
      window.open(src)
    },
    showTTTQM (src, e) {
      e.stopPropagation()
      window.open(src)
    },
    showTQM (src, e) {
      e.stopPropagation()
      window.open(src)
    },
    formatDMY (date) {
      return this.moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.mdt-info {
  background: #fff;
  color: black;
}
.header-info {
  width: 100%;
}
.item-info {
  width: 290px;
}
.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.item-status {
  width: 110px;
}
.search-disease {
  height: 47px;
}
.filter-status {
  color: black !important;
  width: 100%;
  max-width: 237px;
  height: 47px;
}
.form-control {
  height: 100% !important;
}
.btn-action {
  height: 32px;
  border-radius: 8px;
  border: 1.5px solid #20419b;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* Primary Blue */

  color: #20419b;
  background-color: #fff;
  text-align: center;
}
.m-auto {
  margin: 0 auto;
}
.bg-active-disease {
  background-color: #f2f4f7;
}
.case-item {
  cursor: pointer;
}
.case-item:hover {
  background-color: #f2f4f7;
}
</style>
