<template>
  <div v-if="currCase">
    <div class="p-3">
      <div class="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <h5>Biên bản hội chẩn</h5>
        <span class="fs-30 cursor-pointer" @click="backToSlide">&times;</span>
      </div>
      <div class="mb-3">
        <div class="row">
          <div class="col-md-6">
            <p class="fs-14 txt-grey-900 mb-2">
              Họ tên người bệnh:
              <span class="fw-700">{{ currCase && currCase.patient_name }}</span>
            </p>
            <div class="d-flex">
              <p class="fs-14 txt-grey-900 mb-2">
                Chẩn đoán:
                <span>{{ currCase && currCase.patient_diagnostic }}</span>
              </p>
            </div>
            <p class="fs-14 txt-grey-900 mb-2">
              Chủ tọa:
              <span>{{ preside && preside.user && preside.user.name }}</span>
            </p>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-between">
              <p class="fs-14 txt-grey-900 mb-2">
                Tuổi:
                <span class="fw-700">
                  {{
                  currCase &&
                  currCase.patient_birthday &&
                  getAges(currCase.patient_birthday)
                  }}
                </span>
              </p>
              <p class="fs-14 txt-grey-900 mb-2">
                Nam/Nữ:
                <span class="fw-700">
                  {{
                  currCase &&
                  currCase.patient_gender &&
                  getGender(currCase.patient_gender)
                  }}
                </span>
              </p>
            </div>
            <div class="d-flex align-items-center mb-2" id="tg">
              <p class="fs-14 txt-grey-900 mb-0 me-2 w-50">Hội chẩn lúc:</p>
              <input
                type="datetime-local"
                class="border fs-18 form-control bg-white"
                v-model="form.mdt_time"
                :readonly="!isMaster"
                @focus="focusInput('tg', $event)"
                @blur="focusInput('', $event)"
              />
            </div>
            <p class="fs-14 txt-grey-900 mb-2">
              Thư ký:
              <span>
                {{
                currCase &&
                currCase.mdt_room_disease &&
                currCase.mdt_room_disease.doctor &&
                currCase.mdt_room_disease.doctor.name
                }}
              </span>
            </p>
          </div>
          <div class="col-12 d-flex mb-2" id="tv">
            <div class="w-150px">
              <p class="fs-14 txt-grey-900 text-nowrap mb-0 me-2">Thành viên tham gia:</p>
            </div>
            <div class="w--150px">
              <textarea
                class="form-control bg-white border"
                v-model="form.members"
                rows="2"
                :readonly="!isMaster"
                @focus="focusInput('tv', $event)"
                @blur="focusInput('', $event)"
              ></textarea>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center" id="mhs">
            <div class="w-150px">
              <p class="fs-14 txt-grey-900 mb-0 me-2 text-nowrap">Mã hồ sơ:</p>
            </div>
            <div class="w--150px">
              <input
                type="text"
                class="border bg-white h-100 fs-18 w-100 form-control"
                v-model="form.profile_code"
                :readonly="!isMaster"
                @focus="focusInput('mhs', $event)"
                @blur="focusInput('', $event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" id="tgdt">
        <p class="fs-14 txt-grey-900">Bệnh nhân đã điều trị từ ngày nào đến ngày nào</p>
        <input
          type="text"
          v-model="form.treatment_time"
          class="border form-control bg-white"
          :readonly="!isMaster"
          @focus="focusInput('tgdt', $event)"
          @blur="focusInput('', $event)"
        />
      </div>
      <div class="mb-3" id="tt">
        <p
          class="fs-14 txt-grey-900"
        >Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc</p>
        <vue-editor
          v-model="form.treatment_progress"
          :editorToolbar="customToolbar"
          :disabled="!isMaster"
          @focus="focusInput('tt', $event)"
          @blur="focusInput('', $event)"
        ></vue-editor>
      </div>
      <div class="mb-3" id="kl">
        <p class="fs-14 txt-grey-900">Kết luận ( sau khi đã khám lại và thảo luận)</p>
        <vue-editor
          v-model="form.conclusion"
          :editorToolbar="customToolbar"
          :disabled="!isMaster"
          @focus="focusInput('kl', $event)"
          @blur="focusInput('', $event)"
        ></vue-editor>
      </div>
      <div class="mb-3" id="hdtt">
        <p class="fs-14 txt-grey-900">Hướng điều trị tiếp</p>
        <vue-editor
          v-model="form.treatment"
          :editorToolbar="customToolbar"
          :disabled="!isMaster"
          @focus="focusInput('hdtt', $event)"
          @blur="focusInput('', $event)"
        ></vue-editor>
      </div>
      <div class="mb-3 d-flex justify-content-end" @click="focusInput('btn', $event)" id="btn">
        <!-- <button
          :disabled="!isMaster || loading"
          class="btn-action btn bg-pri bd-pri text-white"
          @click="backToSlide"
        >Quay lại trình bày</button>-->
        <button
          :disabled="!isMaster || loading"
          class="btn-action btn bg-pri bd-pri text-white"
          @click="submit()"
        >Lưu</button>
        <!-- <button
          :disabled="!isMaster || loading"
          class="btn-action btn bg-pri bd-pri text-white"
          @click="submit(true)"
        >
          Kết thúc
        </button>-->
        <button class="btn-action btn bg-pri bd-pri text-white" disabled>Ký số</button>
        <button class="btn-action btn bg-pri bd-pri text-white" disabled>In</button>
      </div>
    </div>

    <ConcludingTemp
      :form="form"
      :save_pdf="save_pdf_count"
      @exportError="loading = false"
      @onSubmiting="onSubmiting"
      :mdt_case="currCase"
      :room_info="room_info"
    />
  </div>
</template>

<script>
import mdtUtils from '../../../utils/mdtUtils'
import ConcludingTemp from './ConcludingTemp.vue'
import { VueEditor } from 'vue2-editor'
import appUtils from '@/utils/appUtils'

export default {
  name: 'ConcludingForm',
  props: [
    'currCase',
    'room_info',
    'checkin_members',
    'prop_form',
    'prop_el_id'
  ],
  components: { ConcludingTemp, VueEditor },
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block', 'link'],
        [
          {
            align: ''
          },
          {
            align: 'center'
          },
          {
            align: 'right'
          },
          {
            align: 'justify'
          }
        ]
      ],
      form: {
        treatment_time: '',
        treatment_progress: '',
        conclusion: '',
        treatment: '',
        mdt_time: '',
        members: '',
        profile_code: ''
      },
      mdtUtils,
      save_pdf_count: 0,
      loading: false,
      update_status: false
    }
  },
  watch: {
    currCase (d) {
      this.handlerDisease(d)
    },
    checkin_members (members) {
      this.handlerMemberstring()
    },
    prop_form (f) {
      if (f) {
        this.form = f
      }
    },
    prop_el_id (id) {
      if (!id) return
      document.getElementById(id).scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    }
  },
  computed: {
    isMaster () {
      if (!this.usr_member) return false
      // return this.usr_member?.role === 4 || this.usr_member?.role === 1
      return true
    },
    preside () {
      return this.room_info?.members?.find(m => m.role === 1)
    },
    secretaries () {
      return this.room_info?.members?.filter(m => m.role === 1)
    },
    usr_member () {
      return this.room_info?.members?.find(m => m.user_id === this.$user?.id)
    },
    langId () {
      return appUtils.getLangId(this.$lang || 'vi')
    }
  },
  mounted () {
    this.user_id = this.$user?.id
    this.handlerDisease(this.currCase)
    this.handlerMemberstring()
    if (this.prop_el_id) {
      document.getElementById(this.prop_el_id).scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    }
  },
  methods: {
    handlerDisease (d) {
      if (d) {
        if (d.concluding) {
          this.form.treatment_time = d.concluding.treatment_time || null
          this.form.treatment_progress =
            d.concluding.treatment_progress || null
          this.form.conclusion = d.concluding.conclusion || null
          this.form.treatment = d.concluding.treatment || null
          if (d.concluding.members) this.form.members = d.concluding.members
          if (d.concluding.profile_code) {
            this.form.profile_code = d.concluding.profile_code
          }
          this.form.mdt_time = d.concluding.mdt_time
            ? this.moment(d.concluding.mdt_time, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY-MM-DDTHH:mm'
            )
            : this.moment('HH:mm:ss').format('YYYY-MM-DDTHH:mm')
        } else {
          if (d.mdt_room_disease?.concluding) {
            const mdtD = d.mdt_room_disease
            const mdtDConcluding = d.mdt_room_disease?.concluding
            this.form.treatment_time =
              mdtDConcluding.treatment_time ||
              `${appUtils.formatDateTime(
                mdtDConcluding.treatment_time_start
              )} - ${appUtils.formatDateTime(
                mdtDConcluding.treatment_time_end
              )}` ||
              null
            this.form.treatment_progress = mdtDConcluding.treatment_progress ||
              mdtD.multilang.find(el => el.lang === this.langId).patient_diseases_current_cure ||
              mdtD.patient_diseases_current_cure || ''
            this.form.conclusion = mdtDConcluding.conclusion ||
              mdtD.multilang.find(el => el.lang === this.langId).conclusion ||
              mdtD.conclusion || ''
            this.form.treatment = mdtDConcluding.treatment ||
                mdtD.multilang.find(el => el.lang === this.langId).treatment ||
                mdtD.treatment || ''
            if (mdtDConcluding.profile_code) {
              this.form.profile_code = mdtDConcluding.profile_code
            }
            this.form.mdt_time = mdtDConcluding.mdt_time
              ? this.moment(
                mdtDConcluding.mdt_time,
                'YYYY-MM-DD HH:mm:ss'
              ).format('YYYY-MM-DDTHH:mm')
              : this.moment('HH:mm:ss').format('YYYY-MM-DDTHH:mm')
          } else {
            this.form.treatment_time = ''
            this.form.treatment_progress = d.patient_diseases_current_cure
            this.form.conclusion = d.conclusion
            this.form.treatment = d.treatment
            this.form.profile_code = d.code
            this.form.mdt_time = this.moment().format('YYYY-MM-DDTHH:mm')
          }
        }
      } else {
        this.form = {
          treatment_time: '',
          treatment_progress: '',
          conclusion: '',
          treatment: '',
          mdt_time: this.moment().format('YYYY-MM-DDTHH:mm'),
          members: '',
          profile_code: ''
        }
      }
    },
    handlerMemberstring () {
      const members = this.checkin_members
      if (members && members.count && !this.form.members) {
        let string = ''
        members.data.forEach(m => {
          if (m.status === 1) {
            string += m?.user?.name + ', '
          }
        })
        this.form.members = string
      }
    },
    submit (update) {
      this.loading = true
      this.update_status = update
      this.save_pdf_count++
    },
    formatHmDMY (date) {
      const string = this.moment(date).format('HH:mm DD/MM/YYYY')
      string.split(' ').join(' ngày ')
      return string
    },
    async onSubmiting (file) {
      if (!this.currCase) return
      if (this.currCase.concluding) {
        await this.mdtCaseConcludingUpdate(file)
      } else {
        await this.mdtCaseConcludingCreate(file)
      }
      this.$emit('refresh')
    },
    async mdtCaseConcludingCreate (file = null) {
      const self = this
      if (!this.isMaster) return
      const params = {
        name: self.currCase.patient_name,
        age: self.currCase.patient_birthday
          ? self.getAges(self.currCase.patient_birthday)
          : null,
        sex: self.currCase.patient_gender,
        address: self.currCase.patient_address,
        public_mdt_case_id: self.currCase.id,
        treatment_time: self.form.treatment_time,
        treatment_progress: self.form.treatment_progress,
        profile_code: self.form.profile_code,
        conclusion: self.form.conclusion,
        treatment: self.form.treatment,
        mdt_time: self.form.mdt_time
          ? this.moment(self.form.mdt_time).format('HH:mm DD-MM-YYYY')
          : this.moment().format('HH:mm DD-MM-YYYY'),
        public_mdt_id: this.room_info?.id,
        members: self.form.members
      }
      if (file) params.file = file
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .mdtCaseConcludingCreate(formData)
        .finally(() => {
          self.onFinishSubmit()
        })
    },
    async mdtCaseConcludingUpdate (file = null) {
      const self = this
      if (!this.isMaster || !self.currCase.concluding) return
      const params = {
        name: self.currCase.patient_name,
        age: self.currCase.patient_birthday
          ? self.getAges(self.currCase.patient_birthday)
          : null,
        sex: self.currCase.patient_gender,
        address: self.currCase.patient_address,
        public_mdt_case_id: self.currCase.id,
        treatment_time: self.form.treatment_time,
        treatment_progress: self.form.treatment_progress,
        profile_code: self.form.profile_code,
        conclusion: self.form.conclusion,
        treatment: self.form.treatment,
        mdt_time: this.moment(self.form.mdt_time).format('HH:mm DD-MM-YYYY'),
        public_mdt_id: this.room_info?.id,
        members: self.form.members
      }
      if (file) params.file = file
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .mdtCaseConcludingUpdate(self.currCase.concluding.id, formData)
        .then(r => {})
        .finally(() => {
          self.onFinishSubmit()
        })
    },
    onFinishSubmit () {
      if (
        this.update_status &&
        this.currCase !== 1 &&
        this.currCase !== 2 &&
        this.currCase !== 3
      ) {
        this.onPublicMdtCaseStatus(1)
      }
      this.$toast.open({
        message: 'Lưu thành công',
        type: 'success'
      })
      this.loading = false
      this.backToSlide()
    },
    async onPublicMdtCaseStatus (stt) {
      if (!this.currCase) return
      const self = this
      const params = {
        status: stt
      }
      await self.$rf
        .getRequest('AuthRequest')
        .publicMdtCaseStatus(this.currCase.id, params)
        .then(() => {
          // self.$toast.open({
          //   message: 'Bắt đầu thành công',
          //   type: 'success'
          // })
        })
        .then(() => {})
        .finally(() => {})
    },
    getAges (date) {
      const bd = this.moment(date, 'YYYY-MM-DD')
      const age = this.moment().diff(bd, 'years')
      return age
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    focusInput (id, e) {
      if (!this.isMaster) return
      this.$emit('changeConcludingForm', this.form, id || null)
    },
    backToSlide () {
      this.$emit('changeShowType', 'slide')
    }
  }
}
</script>

<style scoped>
.form-control {
  height: unset;
}
.btn-action {
  width: 150px;
  margin-left: 24px;
}
.fs-18 {
  font-size: 18px;
}
.w-150px {
  width: 150px;
}
.w--150px {
  width: calc(100% - 150px);
}
.form-control {
  border-radius: 0 !important;
}
</style>
